export const clients = [
  "192.168.103.153",
  "aimo-corp-test.vulog.center",
  "aimo-corp.vulog.center",
  "arvalqa-corp-qa.vulog.center",
  "businessfo-dev.vulog.center",
  "corpo-corp-poc.vulog.center",
  "demo-ff-corp.vulog.center",
  "demo-franchise-corp.vulog.center",
  "demo-sched-corp.vulog.center",
  "eloop-corp-test.vulog.center",
  "eloop-corp.vulog.center",
  "emov-corp-test.vulog.center",
  "enuu-corp-test.vulog.center",
  "enuu-corp.vulog.center",
  "epilot-corp-poc.vulog.center",
  "evie-corp-test.vulog.center",
  "evie-corp.vulog.center",
  "evo-corp-test.vulog.center",
  "evo-corp.vulog.center",
  "f2m-corp-test.vulog.center",
  "ffgwen-corp-qa.vulog.center",
  "fr-corp-dev.vulog.center",
  "fr-corp-qa.vulog.center",
  "getaround-corp-test.vulog.center",
  "hourcar-corp-test.vulog.center",
  "hourcar-corp.vulog.center",
  "kintoeu-corp-test.vulog.center",
  "kintoeu-corp.vulog.center",
  "kintopoc-corp-test.vulog.center",
  "leongo-corp-test.vulog.center",
  "leongo-corp.vulog.center",
  "mevo-corp-test.vulog.center",
  "mevo-corp.vulog.center",
  "mitta-corp-test.vulog.center",
  "mitta-corp.vulog.center",
  "mobee-corp-test.vulog.center",
  "mobee-corp.vulog.center",
  "mobile-corp-qa.vulog.center",
  "mol-corp-test.vulog.center",
  "mol-corp.vulog.center",
  "multi-corp-dev.vulog.center",
  "multi-corp-qa.vulog.center",
  "originenergy-corp-test.vulog.center",
  "poppy-corp-test.vulog.center",
  "poppy-corp.vulog.center",
  "qasb-corp-qa.vulog.center",
  "qasb-franchise-corp-qa.vulog.center",
  "racv-corp-qa.vulog.center",
  "rulla-corp-test.vulog.center",
  "rulla-corp.vulog.center",
  "sbgwen-corp-qa.vulog.center",
  "sbmulti-corp-dev.vulog.center",
  "troopy-corp-test.vulog.center",
  "vulogqa-corp-qa.vulog.center",
  "vw-corp-test.vulog.center",
  "vw-corp.vulog.center",
  "vy-corp-test.vulog.center",
  "wible-corp-test.vulog.center",
  "wible-corp.vulog.center",
  "wigo-corp.vulog.center"
];