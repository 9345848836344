import axios from 'axios';
import utils from '../shared/utils';

const hostname = utils.getHostname();
const localiseEndpoint = `https://${hostname}/fe-api/anonymous/bbo/localise`;
const version = process.env.REACT_APP_VERSION || process.env.REACT_APP_BUILD_ID;

/**
 * Returns the translations of all the locales
 * @returns {JSON}
 */
export const getAllTranslations = async () => {
  const response = await axios.get(`${localiseEndpoint}?${version}`);

  return response.data;
};

/**
 * Returns the translations of a specific locale
 * @param {string} locale A specific locale
 * @returns {JSON}
 */
export const getTranslation = async locale => {
  const response = await axios(`${localiseEndpoint}/${locale}?${version}`);

  return response.data;
};

/**
 * Returns an array with objects
 * e.g.
 * [{
 *    "lang_iso": "en_GB",
 *    "lang_name: "English (United Kingdom)"
 * }]
 * @returns {object} lang_iso with lang_name
 */
export const getLocales = async () => {
  const response = await axios(`${localiseEndpoint}/locales?${version}`);

  return response.data;
};
