import { CREATE, UPDATE, GET_LIST, GET_ONE } from 'react-admin';
import utils from '../../shared/utils';

const dataBusiness = (instance, type, params, config) => {
  const opt = {};
  const apiUrl = `/business/fleets/${localStorage.getItem('fleetID')}/business`;
  let clonedData;
  switch (type) {
    case GET_LIST: {
      opt.url = apiUrl;
      opt.method = 'GET';
      // Allow business admin to make direct call for business list
      if (utils.getRoles() === 'admin') {
        const query = new URLSearchParams();
        query.append('status', params.filter.status && params.filter.status !== 'All' ? params.filter.status : '');
        if (params.pagination) {
          query.append('page', params.pagination.page - 1);
          query.append('size', params.pagination.perPage);
        }
        if (params.sort) {
          query.append('sort', params.sort.field + (params.sort.order === 'ASC' ? ',asc' : ',desc'));
        }
        if (params.filter && params.filter.q) {
          query.append('name', params.filter.q);
        }
        opt.params = query;
        return instance(opt);
      }
      // We add a new call to check if this user is part of more than 1 businesses, if not, we will force to load
      // business details of the only business
      let query = new URLSearchParams();
      query.append('page', 0);
      query.append('size', 1);
      opt.params = query;
      return instance(opt).then(res => {
        // Redirect to business details page if only found 1 business
        const { data } = res;
        if (data.totalElements === 1 && data.content && data.content[0].id !== undefined) {
          const linkDetails = `/#/business/${data.content[0].id}`;
          window.location.href = linkDetails;
          return res;
        }
        // Proceed to call for business list with filters if there're more than 1 businesses
        query = new URLSearchParams();
        query.append('status', params.filter.status && params.filter.status !== 'All' ? params.filter.status : '');
        if (params.pagination) {
          query.append('page', params.pagination.page - 1);
          query.append('size', params.pagination.perPage);
        }
        query.append('sort', params.sort.field + (params.sort.order === 'ASC' ? ',asc' : ',desc'));
        opt.params = query;
        return instance(opt);
      });
    }
    case GET_ONE:
      utils.setParameters({ business: { id: params.id } });
      opt.url = `${apiUrl}/${params.id}`;
      opt.method = 'GET';
      break;
    case CREATE:
      opt.url = `${apiUrl}`;
      opt.method = 'POST';
      clonedData = params.data;
      if (clonedData.billingGroup && clonedData.billingGroup.id === null) {
        clonedData.billingGroup = null;
      }
      if (clonedData.billingGroup && clonedData.billingGroup.id) {
        clonedData.billingGroup = { ...clonedData.billingGroup, discount: '', fleetId: '', name: '' };
      }
      if (clonedData.address.country && clonedData.address.country === ' ') {
        delete clonedData.address.country;
      }
      if (clonedData.useAsBillingAddress) {
        clonedData.billingAddress = clonedData.address;
      } else if (clonedData.billingAddress.country && clonedData.billingAddress.country === ' ') {
        delete clonedData.billingAddress.country;
      }
      if (clonedData.address && clonedData.address.id === 'ref') {
        delete clonedData.address.id;
      }
      if (clonedData.billingAddress.id === 'ref') {
        delete clonedData.address.id;
      }
      delete clonedData.useAsBillingAddress;
      opt.data = clonedData;
      opt.data.fleetId = config.business_ws.fleetId;
      break;
    case UPDATE:
      opt.url = `${apiUrl}/${params.id}`;
      opt.method = 'PUT';
      clonedData = params.data;
      if (clonedData.billingGroup && clonedData.billingGroup.id === null) {
        clonedData.billingGroup = null;
      }
      if (clonedData.address.country && clonedData.address.country === ' ') {
        delete clonedData.address.country;
      }
      if (clonedData.useAsBillingAddress) {
        clonedData.billingAddress = clonedData.address;
      } else if (clonedData.billingAddress.country && clonedData.billingAddress.country === ' ') {
        delete clonedData.billingAddress.country;
      }
      if (clonedData.address && clonedData.address.id === 'ref') {
        delete clonedData.address.id;
      }
      if (clonedData.billingAddress.id === 'ref') {
        delete clonedData.billingAddress.id;
      }
      if (clonedData.businessOwner && clonedData.businessOwner.id === null) {
        clonedData.businessOwner = null;
      }
      delete clonedData.useAsBillingAddress;
      delete clonedData.services;
      opt.data = Object.assign({}, clonedData);
      opt.data.fleetId = config.business_ws.fleetId;
      utils.hiddenLoadingScreen();
      break;
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }
  return instance(opt);
};

export default dataBusiness;
