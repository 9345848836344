export const isPrivateServicesActive = config => {
  if (config) {
    // If no conf exist at all
    if (config.privateFleetsAvailable === undefined && config.privateServicesAvailable === undefined) return true;

    // If privateFleetsAvailable is not yet removed and privateServicesAvailable is not yet defined
    if (config.privateFleetsAvailable !== undefined && config.privateServicesAvailable === undefined)
      return config.privateFleetsAvailable;

    // If privateFleetsAvailable has been removed and privateServicesAvailable has been defined
    if (config.privateServicesAvailable !== undefined) return config.privateServicesAvailable;
  }

  return true;
};

/**
 * Removes leading and trailing whitespace from string.
 * @param {string} string
 * @returns {string} - Returns the string with leading and trailing whitespace removed.
 */
export const trimString = string => {
  if (typeof string !== 'string') return string;
  return string.trim();
};
