import React from 'react';
import PropTypes from 'prop-types';
import { TextInput, SimpleShowLayout, translate as translateHOC } from 'react-admin';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Header from '../../../shared/header';
import { maxLength, nonEmptyString, required } from '../../../core/validators';
import Divider from '../../../shared/divider';
import { trimString } from '../../../helpers';

const BusinessAdditionalInfo = ({ isItaly, resource, classes, translate }) => {
  const getFieldLabel = field => translate(`resources.business.fields.${field}`);

  if (!isItaly) return null;

  return (
    <SimpleShowLayout resource={resource}>
      <Header label="common.null" />
      <Header label="resources.business.businessAdditionalInfo" />
      <TextInput
        source="nationality"
        label={getFieldLabel('nationality')}
        validate={[required, nonEmptyString, maxLength(128)]}
        className={classes.fieldEdit}
        required
        format={trimString}
        id="businessNationality"
      />
      <TextInput
        source="fiscalCode"
        label={getFieldLabel('fiscalCode')}
        validate={[required, nonEmptyString, maxLength(128)]}
        className={classes.fieldEdit}
        required
        format={trimString}
        id="businessFiscalCode"
      />
      <TextInput
        source="pec"
        label={getFieldLabel('pec')}
        validate={[required, nonEmptyString, maxLength(128)]}
        className={classes.fieldEdit}
        required
        format={trimString}
        id="businessPec"
      />
      <TextInput
        source="sdicemCode"
        label={getFieldLabel('sdicemCode')}
        validate={[required, nonEmptyString, maxLength(128)]}
        className={classes.fieldEdit}
        required
        format={trimString}
        id="businessSdiCemCode"
      />
      <TextInput
        source="legalForm"
        label={getFieldLabel('legalForm')}
        validate={[required, nonEmptyString, maxLength(128)]}
        className={classes.fieldEdit}
        required
        format={trimString}
        id="businessLegalForm"
      />
      <TextInput
        source="address.region"
        label={getFieldLabel('mainRegion')}
        validate={[required, nonEmptyString, maxLength(64)]}
        className={classes.fieldEdit}
        required
        format={trimString}
        id="businessMainAddress"
      />
      <TextInput
        source="billingAddress.region"
        label={getFieldLabel('invoicingRegion')}
        validate={[required, nonEmptyString, maxLength(64)]}
        className={classes.fieldEdit}
        required
        format={trimString}
        id="businessInvoicingAddress"
      />
      <Divider />
    </SimpleShowLayout>
  );
};

const mapStateToProps = state => ({
  isItaly: state.configuration.fleet.timezone === 'Europe/Rome',
});

BusinessAdditionalInfo.propTypes = {
  classes: PropTypes.shape({}),
  resource: PropTypes.string,
  isItaly: PropTypes.string,
  translate: PropTypes.func,
};

const enhance = compose(
  connect(mapStateToProps),
  translateHOC
);

export default enhance(BusinessAdditionalInfo);
