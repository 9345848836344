import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TextInput, SimpleShowLayout, SelectInput, ReferenceInput, AutocompleteInput } from 'react-admin';
import Header from '../../../shared/header';
import { email, required, number, stringValue, maxLength, minLength, validVat } from '../../../core/validators';
import { getBusinessConf } from '../../../core/config';
import utils from '../../../shared/utils';
import { countryList } from '../../../shared/country';
import AutocompleteChips from '../../../shared/Autocomplete-chips';
import { isPrivateServicesActive } from '../../../helpers';

const BusinessDetailsUnconnected = ({
  id,
  resource,
  options,
  privateFleet: { privateFleetDefaultOptions, setPrivateFleetSelection },
  classes,
  translate,
  isAdmin,
}) => {
  const { config } = getBusinessConf();
  const languageList =
    config && config.translation && config.translation.invoiceLanguage
      ? config.translation.invoiceLanguage.map(language => ({
          id: language.code,
          name: language.label,
        }))
      : [];
  const role = utils.getRoles(id);

  const taxNumberCountry = config && config.taxNumberEnable && (
    <AutocompleteInput
      source="taxNumberCountry"
      choices={countryList}
      className={classes.fieldEdit}
      classes={{ suggestionsContainerOpen: classes.autoCompleteContainer }}
      autoComplete="nope"
      id="Selenium-Business-Edit-Address-Country-Input"
    />
  );

  const communityTaxNumber = config && config.taxNumberEnable && (
    <TextInput
      source="communityTaxNumber"
      validate={[maxLength(20)]}
      className={classes.fieldEdit}
      id="Selenium-Business-Edit-communityTaxNumber-Input"
    />
  );

  const invoiceFrequencyList = [
    {
      id: 'DAILY',
      name: translate(`resources.business.fields.invoiceFrequencies.daily`),
    },
    {
      id: 'MONTHLY',
      name: translate(`resources.business.fields.invoiceFrequencies.monthly`),
    },
  ];

  const privateFleets = isAdmin && isPrivateServicesActive(config) && (
    <AutocompleteChips
      classes={{ root: `${classes.privateFleetsContainer} ${classes.fieldEdit}` }}
      isMulti
      label="resources.business.fields.privateFleetsAvailable"
      options={options}
      defaultValue={privateFleetDefaultOptions}
      placeholder=""
      onChange={selection => setPrivateFleetSelection(selection)}
    />
  );

  return (
    <SimpleShowLayout resource={resource}>
      <Header label="resources.business.businessDetails" />
      <TextInput
        source="name"
        validate={[required, stringValue, maxLength(64)]}
        required
        className={classes.fieldEdit}
        id="Selenium-Business-Edit-Name-Input"
      />
      <TextInput
        source="legalName"
        validate={[required, stringValue, maxLength(64)]}
        className={classes.fieldEdit}
        id="Selenium-Business-Edit-legalName-Input"
      />
      <TextInput
        source="phoneNumber"
        validate={[number, maxLength(15)]}
        className={classes.fieldEdit}
        id="Selenium-Business-Edit-phoneNumber-Input"
      />
      <TextInput
        source="faxNumber"
        validate={[number, maxLength(15)]}
        className={classes.fieldEdit}
        id="Selenium-Business-Edit-faxNumber-Input"
      />
      <TextInput
        source="email"
        type="email"
        validate={[email, maxLength(124)]}
        className={classes.fieldEdit}
        id="Selenium-Business-Edit-Email-Input"
      />
      <TextInput
        source="vat"
        validate={
          config && config.isCountryCodeVat
            ? [required, minLength(4), maxLength(15), validVat]
            : [required, maxLength(32)]
        }
        required
        className={classes.fieldEdit}
        id="Selenium-Business-Edit-Vat-Input"
      />
      <TextInput
        source="ein"
        validate={[required, maxLength(32)]}
        required
        className={classes.fieldEdit}
        id="Selenium-Business-Edit-Ein-Input"
      />
      <TextInput
        source="sdicemCode"
        label="SDI / PEC"
        className={classes.fieldEdit}
        validate={[maxLength(124)]}
        id="Selenium-Business-Edit-sdicemCode-Input"
      />
      <ReferenceInput
        label="resources.business.fields.billingGroup.name"
        source="billingGroup.id"
        resource="name"
        reference="billingGroup"
        className={classes.fieldEdit}
      >
        <SelectInput disabled={role === 'owner'} source="name" id="Selenium-Business-Edit-billingGroup-Select" />
      </ReferenceInput>
      <ReferenceInput
        label="resources.business.fields.businessOwner.fullName"
        source="businessOwner.id"
        resource="fullName"
        reference="businessOwner"
        className={classes.fieldEdit}
      >
        <SelectInput source="fullName" optionText="fullName" id="Selenium-Business-Edit-businessOwner-Name-Select" />
      </ReferenceInput>
      <SelectInput
        source="status"
        choices={[
          { id: 'APPROVED', name: 'Approved' },
          { id: 'REJECTED', name: 'Rejected' },
          { id: 'SUSPENDED', name: 'Suspended' },
        ]}
        className={classes.fieldEdit}
        id="Selenium-Business-Edit-Status-Select"
      />
      {taxNumberCountry}
      {communityTaxNumber}
      {privateFleets}
      <SelectInput
        source="locale"
        choices={languageList}
        className={classes.fieldEdit}
        validate={[required, maxLength(5)]}
        required
        id="Selenium-Business-Edit-language-Select"
      />
      <SelectInput
        source="invoiceFrequency"
        id="Selenium-Business-Edit-invoiceFrequency-Select"
        className={classes.fieldEdit}
        choices={invoiceFrequencyList}
        allowEmpty={false}
      />
    </SimpleShowLayout>
  );
};

BusinessDetailsUnconnected.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})),
  privateFleet: PropTypes.shape({
    privateFleetDefaultOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    setPrivateFleetSelection: PropTypes.func,
  }),
  classes: PropTypes.shape({}),
  resource: PropTypes.string,
  id: PropTypes.string,
  translate: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  const fleetId = localStorage.getItem('fleetID');
  return {
    options: state.services.reduce((acc, service) => {
      if (service.visibility === 'PRIVATE' && service.fleetId === fleetId) {
        acc.push({ label: service.name, value: service.id });
      }
      return acc;
    }, []),
  };
};

export const BusinessDetails = connect(mapStateToProps)(BusinessDetailsUnconnected);

export const BusinessDetailsTest = BusinessDetailsUnconnected;
