import React from 'react';
import PropTypes from 'prop-types';
import { TextField, SimpleShowLayout, translate as translateHOC } from 'react-admin';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Header from '../../../shared/header';
import Divider from '../../../shared/divider';

const BusinessAdditionalInfo = ({ isItaly, record, resource, classes, translate }) => {
  const getFieldLabel = field => translate(`resources.business.fields.${field}`);

  if (!isItaly) return null;

  return (
    <>
      <SimpleShowLayout resource={resource} record={record}>
        <Header label="resources.business.businessAdditionalInfo" />
        <TextField
          source="nationality"
          label={getFieldLabel('nationality')}
          className={classes.field}
          classes={{ root: classes.fieldContent }}
          id="businessNationality"
        />
        <TextField
          source="fiscalCode"
          label={getFieldLabel('fiscalCode')}
          className={classes.field}
          classes={{ root: classes.fieldContent }}
          id="businessFiscalCode"
        />
        <TextField source="pec" className={classes.field} classes={{ root: classes.fieldContent }} id="businessPec" />
        <TextField
          source="sdicemCode"
          label={getFieldLabel('sdicemCode')}
          className={classes.field}
          classes={{ root: classes.fieldContent }}
          id="businessSdiCemCode"
        />
        <TextField
          source="legalForm"
          label={getFieldLabel('legalForm')}
          className={classes.field}
          classes={{ root: classes.fieldContent }}
          id="businessLegalForm"
        />
        <TextField
          source="address.region"
          label={getFieldLabel('mainRegion')}
          className={classes.field}
          classes={{ root: classes.fieldContent }}
          id="businessMainAddress"
        />
        <TextField
          source="billingAddress.region"
          label={getFieldLabel('invoicingRegion')}
          className={classes.field}
          classes={{ root: classes.fieldContent }}
          id="businessInvoicingAddress"
        />
      </SimpleShowLayout>
      <Divider />
    </>
  );
};

const mapStateToProps = state => ({
  isItaly: state.configuration.fleet.timezone === 'Europe/Rome',
});

BusinessAdditionalInfo.propTypes = {
  classes: PropTypes.shape({}),
  resource: PropTypes.string,
  isItaly: PropTypes.string,
  record: PropTypes.shape({}),
  translate: PropTypes.func,
};

const enhance = compose(
  connect(mapStateToProps),
  translateHOC
);

export default enhance(BusinessAdditionalInfo);
